<template>
  <div class="header-wrapper">
    <div class="header-container">
      <a href="">
        <img src="~common/images/top_logo.png" class="logo-box" alt="">
      </a>
      <ul class="header-tab">
        <li class="tab-item"><router-link  to="/" class="tab-desc-item">首页<div :class="selectIndex==1 ? 'active-index':'unactive-index'"></div></router-link></li>
        <li class="tab-item"><router-link  to="/case">营销案例<div :class="selectIndex==2 ? 'active':'unactive'"></div></router-link></li>
        <li class="tab-item"><router-link to="/service">服务支持<div :class="selectIndex==3 ? 'active':'unactive'"></div></router-link></li>
        <li class="tab-item"><router-link to="/info">关于我们<div :class="selectIndex==4 ? 'active':'unactive'"></div></router-link></li>
      </ul>
      <img src="~common/images/top_tel.png" class="tel-box" alt="">
      <div class="telnum-box">173-2785-3010</div>
      <div class="btn1-box" @click="tapUse">免费试用</div>
      <div class="btn2-box" @click="tapLogin">登录</div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
  export default {
    data() {
      return {
        selectIndex: 1
      }
    },
    created() {
      console.info(this.$route.path)
    },
    activated() {
      if (this.$route.path.indexOf('info') > -1) {
        this.selectIndex = 4
        window._hmt.push(['_trackEvent', '顶部tab', '关于我们']);
      } else if (this.$route.path.indexOf('service') > -1) {
        this.selectIndex = 3
        window._hmt.push(['_trackEvent', '顶部tab', '服务支持']);
      } else if (this.$route.path.indexOf('case') > -1) {
        this.selectIndex = 2
        window._hmt.push(['_trackEvent', '顶部tab', '营销案例']);
      } else {
        this.selectIndex = 1
        window._hmt.push(['_trackEvent', '顶部tab', '首页']);
      }
    },
    methods: {
      tapUse() {
        window._hmt.push(['_trackEvent', '顶部tab', '免费试用']);
        window.open('https://www.smtyxy.com/wxapp/index.html')
      },
      tapLogin() {
        window._hmt.push(['_trackEvent', '顶部tab', '登录']);
        window.open('https://www.smtyxy.com/wxapp/index.html')
      }
    },
    watch: {},
    components: {}
  }
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
  .header-wrapper
    position fixed
    top 0
    left 0
    width 100%
    min-width 1270px
    height .48rem
    background: #2356F7
    z-index: 99
    .header-container
      width 7.3958rem
      height 100%
      margin 0 auto
    .header-tab
      display inline-block
      height 100%
      margin-left .22rem
      float left
      cursor pointer
      .tab-item
        position relative
        display inline-block
        padding-left .08rem
        padding-right .08rem
        height 100%
        font-size .11rem
        line-height: 0.48rem;
        box-sizing border-box
        color #fff
        text-align center
      .active-index
        margin-top: -0.02rem
        margin-left: 0.01rem
        width: 0.19rem
        height: 0.02rem
        background:#FFFFFF;
      .unactive-index
        margin-top: -0.02rem
        margin-left: 0.01rem
        width: 0.19rem
        height: 0.02rem
      .active
        margin-left: 0.11rem
        margin-top: -0.02rem
        width: 0.19rem
        height: 0.02rem
        background:#FFFFFF;
      .unactive
        margin-left: 0.11rem
        margin-top: -0.02rem
        width: 0.19rem
        height: 0.02rem
    .logo-box
      display inline-block
      float left
      width: 1.21rem
      height .26rem
      padding-top .11rem
      margin-left 0.61563rem
    .tel-box
      display inline-block
      float left
      width:0.22rem
      height .22rem
      padding-top .13rem
      margin-left 0.15rem
    .telnum-box
      display inline-block
      margin-left 0.05rem
      font-size: 0.11rem;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 0.48rem;
    .btn1-box
      cursor pointer
      display inline-block
      margin-left 0.32rem
      width: 0.68rem;
      height: 0.2rem;
      background: #FF9D44;
      border-radius: 0.04rem;
      font-size: 0.08rem;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 0.2rem;
      text-align: center
    .btn2-box
      cursor pointer
      display inline-block
      margin-left 0.07rem
      width: 0.68rem;
      height: 0.2rem;
      border-radius: 0.04rem;
      opacity: 0.7;
      border: 0.01rem solid #FFFFFF;
      font-size: 0.08rem;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 0.2rem;
      text-align: center

</style>
