<template>
  <div class="footer-wrapper">
    <div class="footer-container">
      <div class="footer-left">
        <div class="footer-item">
          <p class="title"　style="padding-right: 0.2rem">北京总部</p>
          <p class="desc">电话：+86 10 6438 0080</p>
          <p class="desc">邮箱：service@greatdata.com.cn</p>
          <p class="desc">地址：北京朝阳区酒仙桥路14号51号楼二层4门202室</p>
        </div>
        <div class="footer-item">
          <p class="title" 　style="padding-right: 0.2rem">杭州办公室</p>
          <p class="desc">电话：+86 571 2820 3231</p>
          <p class="desc">邮箱：service@greatdata.com.cn</p>
          <p class="desc">地址：杭州市西湖区万塘路8号黄龙时代广场A座601</p>
        </div>
        <div class="footer-item">
          <p class="title">关注公众号，了解更多资讯</p>
          <p class="desc"><img src="~common/images/icon_gdt_code.png" class="gdt-code" alt=""></p>
        </div>
      </div>
    </div>
    <p class="fotter-bottom"><a href="https://smtyxy.com" style="cursor: pointer">smtyxy.com</a><a href="https://beian.miit.gov.cn/" style="cursor: pointer"> All Rights Reserved京ICP备19054033号-13</a></p>
  </div>
</template>

<script type="text/ecmascript-6">
  export default {}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
  .footer-wrapper
    width 100%
    background #284388
    padding-top .24rem
    .footer-container
      width 100%
      color #fff
      .footer-left
        display inline-block
        overflow hidden
        margin-left: 1.89rem
        padding-bottom: 0.49rem
      .footer-item
        display inline-block
        float left
        width: 2.3rem
        .title
          display inline-block
          font-size: 0.06rem;
          border-bottom: 0.01rem solid #FFFFFF;
          margin-bottom .10417rem
          letter-spacing: 1px;
          padding-bottom: 0.04rem
        .desc
          font-size: 0.06rem;
          letter-spacing: 1px;
          line-height: 0.12rem;
          .gdt-code
            width .45rem
            margin-top -0.06rem
            margin-left: 0.18rem
    .fotter-bottom
      border-top: 0.01rem solid #979797;
      font-size: 0.07rem;
      font-weight: 500;
      color: #FFFFFF;
      letter-spacing: 1px;
      text-align: center;
      padding: 0.24rem 0
</style>
