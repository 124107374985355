<template>
  <div class="aside-wrapper">
    <div class="aside-item" @click="tapUse">
      <img src="~common/images/icon_aside_1.png" class="aside-icon">
    </div>
    <div class="aside-item"  style="margin-top:0.1rem">
      <img src="~common/images/icon_aside_2.png" class="aside-icon" @click="serviceClick">
    </div>

    <div class="pop" v-show="isShowDialog">
      <img src="~common/images/icon_aside_pop_bg.png" class="pop-bg">
      <div class="title">购买咨询</div>
      <div><img src="~common/images/icon_aside_pop_qrcode.png" class="pop-qrcode"></div>
      <div class="subtitle">微信添加好友即可咨询</div>
      <div class="desc">客服电话：173-2785-3010</div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
  export default {
    data() {
      return {
        isShowDialog: false
      }
    },
    methods: {
      tapUse() {
        window._hmt.push(['_trackEvent', '首页悬浮', '免费试用']);
        window.open('https://www.smtyxy.com/wxapp/index.html')
      },
      serviceClick() {
        window._hmt.push(['_trackEvent', '首页悬浮', '购买咨询']);
        this.isShowDialog = !this.isShowDialog
      }
    }
  }
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
  .aside-wrapper
    position fixed
    right 0.12rem
    bottom 2.54167rem
    width 0.4rem
    height 0.9rem
    .aside-item
      width 100%
      height .4rem
      box-sizing border-box
      text-align center
      color #fff
      box-shadow: 0rem 0.03rem 0.07rem 0rem rgba(0,0,0,0.2600);
      cursor pointer
      .aside-icon
        width .4rem
    .pop
      position absolute
      box-shadow: 0rem 0.01rem 0.1rem 0rem rgba(159, 159, 160, 0.45);
      right: 0.59rem
      width 1.35rem
      top: 0.34rem
      .pop-bg
        width 1.49rem
        height: 1.47rem
      .pop-qrcode
        width .56rem
        height: .56rem
        position absolute
        top: 0.34rem
        left: 0.41rem
      .title
        font-size: 0.1rem;
        font-weight: bold;
        color: #333333;
        position absolute
        top: 0.13rem
        left: 0.48rem
      .subtitle
        font-size: 0.1rem;
        font-weight: bold;
        color: #333333;
        position absolute
        top: 0.98rem
        left: 0.18rem
      .desc
        font-size: 0.09rem;
        font-weight: bold;
        color: #FFFFFF;
        position absolute
        top: 1.28rem
        left: 0.15rem
</style>
