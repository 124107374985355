<template>
  <div class="index-wrapper" ref="index">
    <div class="index-inner">
      <p-header></p-header>
      <div class="banner">
        <img src="~common/images/info_top.png" width="100%" alt="">
        <div v-show="detailIndex==1" class="banner-title">引爆消费者复购动因</div>
        <div v-show="detailIndex==1" class="banner-subtitle">有效拉动乳制品复购</div>
        <div v-show="detailIndex==2" class="banner-title">快速建立饮料品牌</div>
        <div v-show="detailIndex==2" class="banner-subtitle">与消费者间的情感链接</div>
        <div v-show="detailIndex==3" class="banner-title">一物一码赋能渠道促销</div>
        <div v-show="detailIndex==3" class="banner-subtitle">助酒水品牌促销力拉满</div>
      </div>

      <div class="index-container" v-show="detailIndex==1">
        <div class="detail-box">
          <!-- <div class="detail-title">示例</div> -->
          <!-- <div class="underline"></div> -->
          <img class="detail-img" src="~common/images/detail_case_1.png" alt="" >
          <!-- <img src="~common/images/index_case_ic.png" alt="" class="case-ic"> -->
          <!-- <div class="title">通过多种创意互动形式</div>
          <div class="subtitle">深入快消行业，洞察带货难题</div> -->
          <div class="content" style="margin-top: 50px">结合一物一码特性，在传统的扫码基础上，增加新颖的任务红包玩法。消费者在扫码获得惊喜红包的同时还可以获得待解锁任务红包，再次复购扫码就可以解锁双份奖励的快乐。 </div>
          <div class="content">通过复购解锁的任务机制让消费者体验“多买多扫多得”的快感，不断提供复购动因，引爆复购！</div>
          <!-- <div class="tuijian">推荐行业：休闲食品、酒水饮料、乳制饮品等</div> -->
        </div>
      </div>

      <div class="index-container" v-show="detailIndex==2">
        <div class="detail-box">
          <!-- <div class="detail-title">示例</div>
          <div class="underline"></div> -->
          <img class="detail-img" src="~common/images/detail_case_2.png" alt="" >
          <!-- <img src="~common/images/index_case_ic.png" alt="" class="case-ic"> -->
          <!-- <div class="title">复购扫码享双倍欢乐</div>
          <div class="subtitle">任务机制红包引爆复购！</div> -->
          <div class="content" style="margin-top: 50px">通过创意有趣的互动抽奖游戏，在吸引消费者购买产品、扫码参与活动的同时，快速建立品牌与消费者间的情感沟通，加深品牌在消费者心中的认知。 </div>
          <div class="content">多种创意互动形式，丰富的活动皮肤模版，针对快销品重要营销节点持续更新，满足品牌不同时间节点营销需求！</div>
          <!-- <div class="tuijian">推荐行业：酒水饮料、乳制饮品等</div> -->
        </div>
      </div>

      <div class="index-container" v-show="detailIndex==3">
        <div class="detail-box">
          <!-- <div class="detail-title">示例</div>
          <div class="underline"></div> -->
          <img class="detail-img" src="~common/images/detail_case_3.png" alt="" >
          <!-- <img src="~common/images/index_case_ic.png" alt="" class="case-ic"> -->
          <!-- <div class="title">一物一码赋能渠道促销</div>
          <div class="subtitle">产品铺市动销的不二法门！</div> -->
          <div class="content" style="margin-top: 50px">深受品牌喜爱，经过市场验证的强力促销玩法。通过一物一码赋能产品及品牌渠道，将线下核销环节智能化、数据化、可视化，极大程度上节约再来一瓶活动核销成本。 </div>
          <div class="content">品牌开展再来一瓶成本更低，消费者到店扫码兑换体验更好，数字化再来一瓶玩法将促销力拉满！</div>
          <!-- <div class="tuijian">推荐行业：酒水饮料、乳制饮品等</div> -->
        </div>
      </div>

      <p-footer></p-footer>
      <aside-bar ></aside-bar>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
  import PHeader from 'components/header/header'
  import PFooter from 'components/footer/footer'
  import asideBar from 'components/asideBar/asideBar'

  export default {
    data() {
      return {
        detailIndex: 1
      }
    },
    created() {
    },
    activated() {
      this.detailIndex = this.$route.query.id
    },
    methods: {
    },
    components: {
      PHeader,
      PFooter,
      asideBar
    }
  }
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
  .index-wrapper
    width 100%
    min-width 1270px
    height 100%
    .index-inner
      padding-top: 0.35rem;
      position relative
      .banner
        position relative
        text-align: center
        width: 100%
        .banner-title
          position absolute
          top 0.58rem
          left 50%
          transform: translateX(-50%)
          font-size: 0.2rem;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 0.44rem;
        .banner-subtitle
          position absolute
          top 0.98rem
          left 50%
          transform: translateX(-50%)
          font-size: 0.2rem;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 0.31rem;
    .index-container
      width 100%
      .bg-gray
        background #fbfcfd
      .detail-box
        background: linear-gradient(146deg, #DEFCFA 0%, rgba(255,255,255,0) 100%, rgba(255,255,255,0) 100%);
        padding-top: 0.21rem
        padding-bottom: 0.47rem
        text-align: center
        width: 100%
        position: relative
        .detail-title
          font-size: 0.16rem;
          font-weight: 500;
          color: #191B31;
          letter-spacing: 1px;
        .underline
          position: relative
          left: 50%
          transform: translateX(-50%)
          margin-top: 0.07rem
          width: 0.19rem;
          height: 0.02rem;
          background: #0082EE;
        .detail-img
          margin-top: 0.15rem
          width: 4.44rem
        .case-ic
          width: 0.14rem
          height: 0.16rem
          position: relative
          top: 0.55rem
          left: 33.5%
          display: block
        .title
          margin-top: 0.45rem
          font-size: 0.16rem;
          font-weight: bold;
          color: #191B31;
          letter-spacing: 1px;
        .subtitle
          font-size: 0.09rem;
          font-weight: bold;
          color: #191B31;
          letter-spacing: 1px;
          margin-top: 0.04rem
          margin-bottom: 0.39rem
        .content
          position: relative
          left: 50%
          transform: translateX(-50%)
          text-align: left
          width: 5.43rem;
          text-indent: 0.2rem
          font-size: 0.1rem;
          font-weight: 400;
          color: #191B31;
          line-height: 0.15rem;
          letter-spacing: 1px;
        .tuijian
          margin-top: 0.1rem
          position: relative
          left: 50%
          transform: translateX(-50%)
          text-align: left
          width: 5.43rem;
          font-size: 0.1rem;
          font-weight: bold;
          color: #191B31;
          line-height: 0.15rem;
          letter-spacing: 1px;
      .desc-box
        width: 100%
        font-size: 0.07rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 0.1rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-top: 0.4rem
        padding-bottom: 0.5rem
        text-align: center;
        height: 0.47rem;
        .desc-img
          width: 0.35rem
          height: 0.35rem
          margin-top: 0.05rem
        .title
          margin-top: 0.12rem
          margin-left: 0.16rem
          display: inline;
        .line
          display: inline;
          width: 0.01rem;
          height: 0.42rem;
          background: #C6D0D8;
          margin: 0 0.16rem
      .bottom-box
        padding-bottom .47rem
      .check-box
        background: #F1FAFC;
        height: 0.54rem
        font-size: 0.16rem;
        font-weight: bold;
        color: #191B31;
        line-height: 0.54rem;
        padding-left: 2.21rem
        .title
          display: inline
          font-size: 0.16rem;
        .btn
          display: inline-block
          width: 0.84rem;
          height: 0.29rem;
          background: #FF9D44;
          border-radius: 0.04rem;
          font-size: 0.09rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 0.29rem;
          text-align: center
          margin-left: 1.8rem
</style>
